/*------------------------------------------------------------------
[16. Tables and Datatables]
*/

/* Generic Tables 
------------------------------------
*/
.table {
    margin-top: 5px;
    thead {
        tr {
            th {
                text-transform: uppercase;
                font-weight: 600;
                font-family:'Montserrat';
                font-size: 13px;
                padding-top: 14px;
                padding-bottom: 14px;
                vertical-align: middle;
                border-bottom: 1px solid fade(@color-master-light, 70%);
                color:fade(@color-master-dark, 35%);
                &[class*='sorting_']:not([class='sorting_disabled']){
                    color:@color-master-dark;
                }
                &:first-child {
                    .padding-left(18px) !important;
                }
                .btn {
                    margin-top: -20px;
                    margin-bottom: -20px;
                }
            }
        }
    }
    tbody {
        tr {
            td {
	            vertical-align: middle;
                background: #fff;
                border-bottom: 1px solid fade(@color-master-light, 70%);
                border-top: 0px;
                padding: 20px 12px;
                font-size: 13.5px;
                .btn-tag {
                    background:fade(@color-master-dark, 7%);
                    display: inline-block;
                    margin: 5px;
                    border-radius: 4px;
                    padding: 5px;
                    color: #62605a !important;
                    &:hover {
                        background:fade(@color-master-dark, 15%);
                    }
                }
                &[class*='sorting_'] {
                    color:#000;
                }
                
            }
            &.selected td {
                background: @color-warning-lighter;
            }
        }
    }
    &.table-hover {
        tbody {
            tr {
                &:hover td {
                    background: @color-complete-lighter !important;
                }
                &.selected:hover td{
                    background: @color-warning-lighter !important;
                }
            }
        }
    }
    &.table-striped {
        tbody {
            tr {
                td {
                    background: @body-background !important;
                }
                &:nth-child(2n+1) td {
                    background: #fff !important;
                }
            }
        }
    }
    &.table-borderless{
         tbody {
            tr {
                td {
                   border-top: 0;
                }
            }
        }       
    }
    &.table-condensed {
        table-layout: fixed;
        thead {
            tr {
                th {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
            }
        }
        thead tr th, tbody tr td, tbody tr td *:not(.dropdown-default){
            white-space: nowrap;
            vertical-align: middle;
            overflow: hidden;
            text-overflow: ellipsis;
            &.reset-overflow *{
                overflow: initial !important;
            }
        }
        &.table-detailed {
            & > tbody > tr {
                &.shown {
                    & > td {
                        background: @color-warning-lighter;
                        &:first-child:before {
                            .rotate(90deg);
                        }
                    }
                    & + tr {
                        & > td {
                            background: fade(@body-background, 40%);
                            padding: 0px 40px;
                            .table-inline{
                                background: transparent;
                                tr,td{
                                background: transparent;
                                font-weight: 600;
                                }
                            }
                        }
                    }
                }
                &.row-details{
                     & > td {
                        &:first-child:before {
                            content: '';
                        }
                    }
                }
                & > td {
                    &:hover {
                        cursor: pointer;
                    }
                    &:first-child:before {
                        content:"\f054";
                        display: inline-block;
                        .margin-right(8px);
                        font-family: 'FontAwesome';
                        .transition(all 0.12s linear);
                    }
                }
            }
            .table-inline {
                td {
                    border: none;
                    text-align: left;
                }
            }
        }
    }
    &.table-borderless{
        & > tbody{
            & > tr{
                & > td{
                    border-bottom: 0px;
                }
            }
        }
    }
}
.fht-table {
    margin-bottom: 0 !important;
}

/* Data-tables 
------------------------------------
*/

.table.dataTable.no-footer{
    border: none;
}
.dataTables_scroll{
    &:hover{
        .dataTables_scrollBody:before{
            content: "";
            top: 0;
            height: 0;
        }
    }
}
.dataTables_scrollBody {
    overflow-y: auto;
    border:none !important;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;
        bottom: 0;
        background: transparent;
    }

}
.dataTables_wrapper .dataTables_paginate{
    .float(right);
}
.dataTables_wrapper .dataTables_paginate ul > li.disabled a{
    opacity: .5;
}
.dataTables_wrapper .dataTables_paginate ul > li > a {
    padding: 5px 10px;
    color: @color-master;
    opacity: .35;
    .transition(opacity .3s ease);
    &:hover{
        opacity: .65;
    }
}

.dataTables_wrapper .dataTables_paginate ul > li.next > a,
.dataTables_wrapper .dataTables_paginate ul > li.prev > a{
    opacity: 1;
} 
.dataTables_wrapper .dataTables_paginate ul > li.disabled a{
    opacity: .35;
    &:hover{
        opacity: .35;
    }
}
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate{
    margin-top: 25px !important;
}
.dataTables_paginate.paging_bootstrap.pagination{
    padding-top: 0;
    .padding-right(20px);
}
.dataTables_wrapper .dataTables_info{
     clear: none;
    font-size: 12px;
    
    padding: 0 33px;
    color: @color-master;
}
.dataTables_wrapper .dataTables_paginate ul > li {
    display: inline-block;
    .padding-left(0);
    font-size: 11px;
}
.dataTables_scrollHeadInner{
    .padding-right(0) !important;
}
.export-options-container{
    position: relative;
}
.dataTables_wrapper .dataTables_paginate ul > li.active > a{
    font-weight: bold;
    color: @color-master;
    opacity: 1;
}
.export-options-container a{
    color: inherit;
    opacity: 1;
}
.exportOptions .DTTT.btn-group a{
    display: block !important;
}

table.dataTable thead {
    .sorting_asc:after{
        background-image: url("../img/icons/sort_asc.png");
    }
    .sorting_desc:after{
        background-image: url("../img/icons/sort_desc.png");
    }
    .sorting:after{
        background-image: url("../img/icons/sort_both.png");
    }
    .sorting_asc_disabled:after{
        background-image: url("../img/icons/sort_asc_disabled.png");
    }
    .sorting_desc_disabled:after{
        background-image: url("../img/icons/sort_desc_disabled.png");
    }
    
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after{
    bottom: 12px;
    content: "";
    width: 19px;
    height: 19px;
    background-position: center center;
    opacity: 1;
}


/* Responsive Handlers : Tables */
@media (max-width: 991px) {
    .dataTables_wrapper .dataTables_info{
        .float(left);
    }
    .dataTables_paginate.paging_bootstrap.pagination{
        .float(right);
    }

}

@media (max-width: 480px) {
  .dataTables_wrapper .dataTables_info,
     .dataTables_wrapper .dataTables_paginate{
        float: none;
        .text-align(left);
        clear: both;
        display: block;
    }
}

/* Demo classes */
.demo-table-search{
    thead th{
        &:nth-child(1){
            width: 20%;
        }
        &:nth-child(2){
            width: 22%;
        }
        &:nth-child(3){
            width: 24%;
        }
        &:nth-child(4){
            width: 15%;
        }
        &:nth-child(5){
            width: 19%;
        }
    }
}

.demo-table-dynamic {
    thead th{
        &:nth-child(1){
            width: 25%;
        }
        &:nth-child(2){
            width: 30%;
        }
        &:nth-child(3){
            width: 20%;
        }
        &:nth-child(4){
            width: 25%;
        }
    }
}

/* Angular UI Grid */
.ui-grid{
  border: none;
}
.ui-grid-top-panel{
    background: transparent;
    .ui-grid-header-cell-label{
        text-transform: uppercase;
        font-weight: 600;
        font-family:'Montserrat';
        font-size: 13px;
        padding-top: 14px;
        padding-bottom: 14px;
        vertical-align: middle;
        border-bottom: 1px solid fade(@color-master-light, 70%);
        color:fade(@color-master-dark, 35%);
    }
}
.ui-grid-canvas .ui-grid-cell{
    height: auto !important;
}
.ui-grid-cell-contents{
    padding: 20px;
    &:focus{
        outline:none !important;
    }
}
.ui-grid-cell,
.ui-grid-header-cell{
    border-right: none;
}
.ui-grid-top-panel .ui-grid-header-cell-label{
    border-bottom: none;
}
.ui-grid-column-menu-button {
    top: 50%;
    margin-top: -13px;
}