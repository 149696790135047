/*------------------------------------------------------------------
[4. Panels]
*/

.panel{
    .box-shadow(none);
    .border-radius(1px);    
    .transition(all .2s ease);
    position: relative;
    .panel-heading{
        background: transparent;
        border-radius:0px;
        border-bottom:0px;
        padding: @panel-heading-padding;
        position: relative;
        z-index: 3;
        min-height: 48px;
        &.separator:after {
                    content:"";
                    height:1px;
                    background:rgba(0,0,0,0.08);
                    left:16px;
                    right:16px;
                    position:absolute;
                    bottom:0;
        }
        & + .panel-body{
            padding-top: 0;
            height: calc(~'100% - 50px');
        }
        .panel-title{
	        width: 100%;
            font-family: 'Montserrat';
            text-transform: uppercase;
            display: inline-block;
            letter-spacing: 0.02em;
            font-size: 12px;
            font-weight: 600;
            margin: 0;
            padding: 0;
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-text-stroke:0px;
            filter: alpha(opacity=40);
            .transition(opacity .3s ease);
            
            .progress-info {
	            float: left;
	            height: 90px;
	            margin: 0 10px 0 0;
	            padding: 21px 20px;
            }
            
            & + .panel-body{

            }
            
            .subtitle {
	            font-size: 13px;
            }
        }
        .panel-controls{
            .float(right);
            margin-top: -3px;
            ul{
                margin: 0;
                padding: 0;
                li{
                    display: inline-block;
                    list-style: none;
                    line-height: 0;
                }
            }
            &:after{
                content:"";
                  display:table;
                  clear:both;
            }
        }
        &.panel-heading-hover{
            .panel-title{
                opacity: .4;
            }
            &:hover{
                .panel-title{
                    opacity: 1;
                }              
            }
        }
    }
    &.panel-default{
        border:1px solid @panel-border-color;
    }
    &.panel-bordered{
        border:1px solid fade(@color-master-light, 70%);
    }
    &.panel-condensed{
        .panel-heading{
            padding: @panel-condensed-heading-padding;
            min-height: 30px;
            .panel-title{
              opacity: .4;
            }
        }
        .panel-body{
            padding: @panel-condensed-body-padding;
        }
    }
    &.panel-hover{
        .panel-heading .panel-title{
            opacity: .4;
        }
        &:hover{
            .panel-heading .panel-title{
                opacity: 1;
            }
        }
    }
    &.panel-transparent{
        background: transparent;
        .box-shadow(none);
        .panel-body{
            background: transparent;
        }
    }
    &.full-height{
        height: calc(~'100%');
        .panel-body{
            height: auto;
            width: 100%;
            height: 100%;
        }
    }
    &.panel-featured {
        .box-shadow(-1px 1px 3px 0px rgba(121, 129, 135, 0.14));
        width: calc(~'100% - 50px');
        .float(right);
        .panel-title {
            h4 {
                font-family: 'Montserrat';
                font-size: 16px;
                text-transform: uppercase;
                color: @color-master-lighter;

            }
        }
        .panel-body {
            h3 {
                line-height: 34px;
                font-size: 26px;
            }
        }
        .footer {
            .username {
                line-height: 8px;
                padding-top: 10px;
                font-size: 16px;
            }
            .buttons {
                li {
                    display: inline;
                    list-style: none;
                    font-weight: bold;
                    .margin-left(20px);
                    &:first-child {
                        .margin-left(0);
                    }
                }
                .heart {
                    color: @color-danger;

                }
                .comment {
                    color: @color-master;
                }
            }
        }
        .ribbon {
            width: 38px;
            height: 38px;
            .margin-left(-39px);
            .float(left);
            .box-shadow( inset -3px 0px 3px 0px rgba(0,0,0,0.14) );
            &.green {
                background: @color-complete;

            }
            &.blue {
                background: @color-success;

            }
        }
    }
    &.hover-fill:hover{
        background:@color-master-lighter;
    }
    &.hover-stroke{
        &:hover{
            border:1px solid @color-master-light;
        }
    }

    .panel-body{
        padding: @panel-padding;
        height: 100%;
        width: 100%;
        //overflow-y: auto;
        &.no-padding{ 
            .row{ 
                margin-left: 0; 
                margin-right: 0; 
            } 
        } 
        &.no-bottom-padding{
            padding-bottom: 0;
        }
        &.no-top-padding{
            padding-top: 0;
        }
        .title{
            margin-top:0px;
        }
        &.scrollable{
            margin-bottom: @panel-padding;
        }
    }
}


/* Portlets
------------------------------------
*/
.portlet-progress{
    background: rgba(255,255,255,.8);
    bottom: 0;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;
    display: none;
    z-index: 2;
}
.portlet-progress > .progress,
.portlet-progress > .progress.progress-small{
    height: 3px;
}
.portlet-progress > .progress-circle-indeterminate,
.portlet-progress > .portlet-bar-indeterminate{
    display: block;
    left: 50%;
    .margin-left(-17px);
    margin-top: -17px;
    position: absolute;
    top: 50%;
}
.portlet-progress > .progress-circle-indeterminate{
    width: 35px;
    height: 35px;
}
.panel-maximized{
    position: fixed !important;
    .left(@layout-sidepanel-width-collapsed);
    top: @layout-header-height - 1px;
    bottom: 0;
    right: 0;
    z-index: @zIndex-portlet;
    margin: 0;
}

/* Pages notification holder */
.panel .pgn-wrapper{
    position: absolute;
    z-index: @zIndex-portlet + 2;
}

.panel-heading a:not(.btn){
    color: @color-master !important;
    opacity: .4;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    position: relative;
    top: -3px;
    &.portlet-refresh{
        .transition(opacity .3s ease);
        &.refreshing{
            opacity: 1;    
        }
        
    }
}
.panel-heading {
    .dropdown ul li a, .dropdown-menu li a{
        padding: 0 20px;
    }
    a[data-toggle]:hover{
    opacity: 1;
    }
}

.portlet-icon{
    -moz-osx-font-smoothing: grayscale;
    font-family: "pages-icon";
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
}
.portlet-icon-close:before{
    content: "\e60a";
}
.portlet-icon-maximize:before{
    content: "\e634";
}
.portlet-icon-refresh:before{
    content: "\e600";
}
.portlet-icon-collapse:before{
    content: "\e62c";
}

.portlet-icon-settings:before{
    content: "\e655";
}
.portlet-icon-refresh-lg-master,
.portlet-icon-refresh-lg-white{
    width: 15px;
    height: 15px;
    display: block;
    background-size: cover;
     .transition(opacity .3s ease);
    &.fade{
        opacity: 0.1;
    }
}
.portlet-icon-refresh-lg-master{
    background-image:url('@{base-img-url}/progress/progress-circle-lg-master-static.svg');
}

.portlet-icon-refresh-lg-white{
    background-image:url('@{base-img-url}/progress/progress-circle-lg-white-static.svg');
}

.portlet-icon-refresh-lg-master-animated,
.portlet-icon-refresh-lg-white-animated{
    width: 15px;
    height: 15px;
    display: block;
    background-size: cover;
    opacity: 0;
    .transition(opacity .3s ease);
    &.active{
        opacity: 1
    }
}
.portlet-icon-refresh-lg-master-animated{
    background-image:url('@{base-img-url}/progress/progress-circle-lg-master.svg');
}
.portlet-icon-refresh-lg-white-animated{
    background-image:url('@{base-img-url}/progress/progress-circle-lg-white.svg');
}


/* For demo purpose only */
.panel-scroll{
    height: 100px;
}
.sortable .panel-heading {
    cursor: move;
}
/* To prevent lag while dragging */
.ui-sortable-handle{
    transition: max-height 0.3s ease 0s;
}
.sortable .grid .grid-title {
    cursor: move;    
}
.ui-sortable {
    min-height: 0px !important;
}
.ui-sortable-placeholder {
    border: 1px dotted black;
    visibility: visible !important;
    height: 100% !important;
    * {
        visibility: hidden;
    }
}
.sortable-box-placeholder {
    background-color: @color-master-lighter;
    border: 1px dashed @color-master-light;
    display: block;
    margin-top: 0px !important;
    margin-bottom: 24px !important;
    * {
        visibility: hidden;
    }
}
.sortable .panel{
    transition: none;
}
.sortable-column{
    padding-bottom: 100px;
}
.demo-portlet-scrollable{
   height:158px
}



/*  
   --------------------------------------
       Custom Panels
   -------------------------------------- 
*/ 

.panel-quote {
	
	
	.sticky-wrapper {
		position: relative;
		z-index: 999;
		
		& ~ .tab-content {
			overflow: visible;
			padding: 15px;
			& > .tab-pane {
				&.slide-left,
				&.slide-right {
					.transition(all .3s ease) !important;
					&.sliding {
						opacity: 0 !important;
					}
					&.active {
						.translate3d(0, 0, 0);
						opacity: 1;
					}
				}
				
				&.slide-left.sliding {
				.translate3d(10%, 0, 0) !important;
				}
				
				&.slide-right.sliding {
				.translate3d(-10%, 0, 0) !important;
				}
			}
		}
	}
	
	
	#elements { display: none; }
	
	
	.nav-tabs-simple {
		text-align: center;
	}
	
	.nav-tabs-simple > li {
		width: 200px;
		float: none;
		display: inline-block;
		text-align: center;
		
		a { 
			padding-top: 10px; 
		}
		
		.infos {
			display: none;
			margin: 14px 0 7px 0;
		}
		
		strong {
			font-size: 12px;
			font-weight: normal;
			color: #aaa;
			display: inline-block;
			margin: 0 10px;
		}
		
		@media screen and (max-width: 1290px) {
			width: 160px;
		}
	}	
	
	.nav-tabs {
		padding: 30px 0 0 0;
		position: relative;
		background: rgba(255,255,255,0.95);
		z-index: 999;
	}
	

	
	
	.tab-content {
		margin: 90px 0 0 0;
		padding: 0 0px 70px 10px; 
		
		.btn {
			display: inline-block;
			padding: 8px 20px;
			line-height: 30px;
			i { right: 5px; }
		}
		
		p.input-name {
			display: none;
			padding: 0;
		}
		
		.total-time,
		.total-price {
			display: none;
		}
	}

	
	table {
		
		tr {
			td:first-child {
				width: 45%;
			}
			
			&.disabled {
				color: #ccc;
				.task-title { color: #ccc; }
			}
			
			&.subtask {
				td:first-child {
					padding-left: 48px;
				}
			}
			
			&.task {
				td:first-child {
					position: relative;
					.btn {
						position: absolute;
						right: 10px;
						top: 30px;
					}
				}
			}
		}
		
		td {
			.switch {
				top: 3px;
			}
			
			.input-name {
			    position: relative;
			    top: -2px;
				padding: 20px 10px;
				display: inline-block;
			}
			
			&.td-inputs {
				display: none;
			}
			
			&.td-display {
				display: table-cell;
			}
			
			
			
			.form-group-default {
				
				margin: 0 10px;
				
				display: inline-block;
				
				input {
					font-size: 13px;
					text-align: center;
					top: 2.5px;
					width: 60px;
					height: 40px;
					line-height: 40px;
					padding: 0 10px;
					&.input-price { width: 85px; }
				}
			
				.input-group-addon {
					color: #999;
					height: 40px;
					padding: 0 15px;
					min-width: 0px;
				}
				
				&.form-group-table {
					top: 0px;
				}

			}
			
			
			&.td-actions {
				position: relative;
				.delete-row {
					position: absolute;
					right: -100px;
					top: 20px;
					height: 43px;
					border-radius: 0;
					-moz-border-radius: 0;
					-webkit-border-radius: 0;
					i { right: 0; }
					
					@media screen and (max-width: 1290px) {
						right: -35px;
					    width: 10px;
					    padding: 8px 15px;
					    i { right: 15px; }
					}
				}
			}
		}
	}
	
	
	.category {
		
		.total-time,
		.total-price {
			font-family: "Segoe UI", Arial, sans-serif;
			position: relative;
			top: -1px;
			font-size: 14px;
			&:before {
				color: #ccc;
				content: '|';
				padding: 7px 14px;
			}
		}
	}
			
	
	
	
	.task-title {
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 400;
		padding: 0 0 0 36px;
		
		.switch {
			top: 12px;
			margin: 0 20px 0 0;
		}
	}
	
	
	
	
	.subcategory {
		
		margin-bottom: 75px;
		
			
		.subcategory-title {
			font-family: 'Montserrat';
			font-size: 18px;
			font-weight: 400;
			margin: 0 0 30px 0;
			
			.switch {
				top: 10px;
				margin: 0 20px 0 0;
			}
			
			button {
				float: right;
				margin: 0 12px 0 0;
			}
		}
	
		&.disabled { 
			.subcategory-title { color: #ccc; }
		}
	}
	
	
	.form-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		line-height: 110px;
		height: 110px;
		background: #F0F0F0;
		text-align: center;
		z-index: 999;
		
		.settings {
			text-align: left;
			.switch {
				top: 11px;
				margin: 0 0 0 15px;
			}
		}
		
		.submit {
			text-align: right;
		}
		
		
		#total {
			font-size: 24px;
			span { margin: 0 20px; }
		}
	}
	
	
	
	/*  
	   --------------------------------------
	       Panel mode affichage
	   -------------------------------------- 
	*/ 
	
	&.panel-simple {
		
		
		.nav-tabs {
			padding: 0 0 0 0;
			.switch, .disabled { display: none; }
		}
		
		
		.nav-tabs-simple > li .infos { display: block; }
				
		
		.tab-content {
			
			// Ce qui disparait
			.td-actions, .switch { display: none; }
			.btn { display: none; }
			.disabled { display: none; }
			.input-name { display: none; }
			.td-display { display: none; }
			
			// Ce qui apparait
			.td-inputs { 
				display: table-cell;
				padding-right: 0 !important; 
			}
			p.input-name { display: inline-block; }
			.total-time,
			.total-price { display: inline-block; }
			
		}
		
	}
	
}