/*------------------------------------------------------------------
[25. Gallery]
*/

.gallery {
    margin: 70px auto 0 auto;
}
.gallery-item {
    overflow: hidden;
    cursor: default;
    background-color: #000;
    margin-bottom: 10px;
    position: relative;
    width: 280px;
    height: 240px;
    &:hover {
        cursor: pointer;
    }
    &[data-width="1"] {
        width: 280px;
    }
    &[data-width="2"] {
        width: 570px;
    }
    &[data-height="1"] {
        height: 240px;
    }
    &[data-height="2"] {
        height: 490px;
    }
    & >img {
        opacity: 1;
        -webkit-transition: opacity 0.35s;
        transition: opacity 0.35s;
    }
    & > .live-tile {
        & img {
            opacity: 1;
            -webkit-transition: opacity 0.35s;
            transition: opacity 0.35s;
        }
    }
    .rating {
        margin-top: -5px;
        color: rgba(255, 255, 255, 0.3);
        & > .rated {
            color: rgba(255, 255, 255, 1);
        }
    }
    .item-info {
        -webkit-transform: translate3d(0, 40%, 0);
        transform: translate3d(0, 40%, 0);
        -webkit-transition: -webkit-transform 0.35s, color 0.35s;
        transition: transform 0.35s, color 0.35s;
        &.more-content {
            -webkit-transform: translate3d(0, 32%, 0);
            transform: translate3d(0, 32%, 0);
        }
    }
    &:hover {
        .item-info {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        & >img {
            opacity: 0.6;
        }
        & > .live-tile {
            & img {
                opacity: 0.6;
            }
        }
    }
    &:active {
        .item-info {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        & >img {
            opacity: 0.6;
        }
    }
}
.item-details {
    z-index: 1050;
    .dialog__content {
        width: 845px;
        max-width: 845px;
        padding: 0;
        .text-align(left);
        border: 1px solid rgba(0, 0, 0, 0.8);
        .dialog__overview {
            height: 516px;
            position: relative;
            .buy-now {
                position: absolute;
                bottom: 20px;
                .right(35px);
            }
            .item-slideshow .slide {
                width: 516px;
                height: 516px;
                display: block;
                overflow: hidden;
            }
        }
        .dialog__footer {
            height: 75px;
            .price {
                margin: 0;
                padding: 0;
                line-height: 75px;
            }
            .separator {
                position: relative;
            }
            .separator:after {
                content: "";
                width: 1px;
                background: rgba(255, 255, 255, .1);
                position: absolute;
                height: 27px;
                .right(0);
                top: 24px;
            }
            .recommended {
                li {
                    a {
                        width: 56px;
                        height: 56px;
                        display: block;
                    }
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .close {
            top: 15px;
            .right(15px);
            z-index: 100;
        }
    }
    .owl-nav {
        .left(0);
        position: absolute;
        top: 50%;
        width: 100%;
        color: #fff;
        opacity: .7;
        font-size: 18px;
        padding: 0 20px;
        .owl-prev {
            .float(left);
        }
        .owl-next {
            .float(right);
        }
    }
    .owl-dots {
        bottom: 23px;
        position: absolute;
        right: 23px;
        .owl-dot {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            display: inline-block;
            background: rgba(0, 0, 0, .3);
            .margin-left(6px);
            &.active {
                background: #fff;
            }
        }
    }
}
.owl-carousel .owl-stage-outer{
    direction: ltr;
}
.gallery-filters{
    position: absolute;
    left: 0;
    right: 0;
    height: 70px;
    top:-70px;
}
@media (max-width: 920px) {
    .gallery-item.first{
        display: none;
    }
}
@media (max-width: 767px) {
    .item-details {
        .dialog__content {
            height: 90%;
            overflow-y: auto;
            width: 400px;
            max-width: 400px;
            .container-fluid {
                height: 100%;
                padding-left: 30px;
                padding-right: 30px;
            }
            .dialog__overview {
                height: 100%;
                margin-right: -30px;
                margin-left: -30px;
            }
        }
        .item-slideshow-wrapper {
            height: 515px !important;
        }
        .item-description {
            height: auto !important;
            .buy-now {
                position: static !important;
                .float(right);
                margin-bottom: 20px;
            }
        }
        .item-slideshow {
            .owl-stage-outer, .owl-stage {
                height: 100%;
            }
            .slide {
                width: 100% !important;
            }
        }
    }
}

@media (max-width: 420px) {
    .gallery{
        margin-top: 80px;
    }
    .gallery-filters{
        top: -90px;
    }
    .item-details .dialog__content {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 610px) {
    .gallery-item, .gallery {
        width: 100% !important;
    }
}
@media (min-width: 768px) {
    .item-details .dialog__content .container-fluid > .row {
        margin-left: -30px;
        margin-right: -30px;
    }
}