div.switch {
    display: inline-block;
    position: relative;
}

div.switch label {
  display: block;
  position: relative;
  color: transparent;
  background: white;
  border: 1px solid #ddd;
  text-indent: 100%;
  width: 44px;
  height: 24.4px;
  cursor: pointer;
  transition: left .15s ease-out;
  border-radius: 100px;
}

div.switch input {
  display: none;
}

div.switch input + label {
  margin-left: 0;
  margin-right: 0
}

div.switch label:after {
  content: "";
  display: block;
  background: #ddd;
  position: absolute;
  top: 1.45px;
  left: 3px;
  width: 20px;
  height: 20px;
  -webkit-transition: left .15s ease-out;
  -moz-transition: left .15s ease-out;
  transition: left .15s ease-out;
  border-radius: 100px;
}

div.switch input:checked + label {
	background: #10cfbd;
	border-color: #10cfbd;
}

div.switch input:checked + label:after {
  left: 20px;
  background: white;
}



	/*  
	   --------------------------------------
	       Taille XS
	   -------------------------------------- 
	*/ 


	div.switch-xs label {
		width: 38px;
	}
	
	div.switch-xs label:after {
		width: 18px;
		height: 18px;
		top: 2px;
	}
	
	div.switch-xs input:checked + label:after {
	  left: 16px;
	}
	
	
	
	
	/*  
	   --------------------------------------
	       Couleurs
	   -------------------------------------- 
	*/ 
	
	div.switch-primary input:checked + label {
		background: #2B303B;
		border-color: #2B303B;
	}