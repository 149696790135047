/*------------------------------------------------------------------
[22. Login]
*/


.login-wrapper{
	height: 100%;
	background-color: @color-primary;
	& > * {
		height: 100%;
	}
	.bg-pic{
		position: absolute;
		width: 100%;
		overflow: hidden;
		& > img{
			height: 100%;
			opacity: 0.6;
		}
	}
	.login-container{
		width: 496px;
		display: block;
		position: relative;
		.float(right);
	}
	.bg-caption{
		width: 500px;
	}
}
.register-container{
	width: 550px;
	margin: auto;
	height: 100%;
}


/* Responsive handlers : Login
------------------------------------
*/

@media (max-width: 768px) {
	.login-wrapper{
		.login-container{
			width: 100%;
		}
	}
	.register-container{
		width: 100%;
		padding: 15px;
	}
}

@media only screen and (max-width : 321px) {
	.login-wrapper{
		.login-container{
			width: 100%;
		}
	}
}