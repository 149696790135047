.page-content-wrapper{
	visibility: hidden;
}
.pace-done .page-content-wrapper{
	visibility: visible;
} 




.list {
	list-style: none;
	margin: 10px 0 20px 0;
	padding: 0 20px;
	
	.list-item {
		position: relative;
		width: 100%;
		font-size: 15.6px;
		letter-spacing: 0px;
		margin: 0;
		color: #626262;
		border-bottom: 1px solid rgba(0,0,0,0.07);
		cursor: pointer;
		
		i { 
			position: relative;
			font-size: 12px;
			top: 6px;
			&:before { padding: 0; }
		}
		
		a {
			position: relative;
			height: 100%;
			padding: 14px 20px;
			display: inline-block;
			color: #626262;
			&:hover { color: #999; }

			
			&.task-pending,
			&.task-current,
			&.task-done {
				&:before {
					width: 3px;
					height: 90%;
					background: #e2e2e2;
					content: '';
					position: absolute;
					left: -3px;
					top: 5%;
				}
			}
			
			&.task-current {
				&:before { 
      				background: #F8D053; 
    			}
			}
			
			&.task-done {
				&:before { 
      				background: #10CFBD; 
    			}
			}
			
			
		}
		
		&:first-child { border-top: 1px solid rgba(0,0,0,0.07); }
		
		
		.progress {
			position: relative;
			top: 25px;
			right: 6px;
			width: 60%;
			float: right;
		}
	 
	}
	
	
	@media screen and (max-width: 1290px)
	{
		.list-item {
			font-size: 14px;
			a {
				padding: 14px 2px;
				&.task-pending,
				&.task-current,
				&.task-done {
					padding-left: 15px;
				}
			}
		}
	}
	
	@media screen and (max-width: 900px)
	{
		.progress {
			top: 0;
			float: none;
			width: 100%;
			margin: 15px 6px 10px 6px;
		}
	}
}




.invoice {
	
	@media print {
	}
}