/*------------------------------------------------------------------
[27. Pricing]
*/

.pricing-table {
    td[class*="bg-"], th[class*="bg-"] {
        border-top-color: transparent;
        border-bottom-color: fade(#000, 2%);
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }
    & > thead > tr > th {
        border-bottom-width: 1px;
    }
    tr td:nth-child(2),
    tr th:nth-child(2) {
        border-left: 1px solid fade(#000, 10%);
    }
}

@media only screen and (max-width: 768px) {
    /* Force table to not be like tables anymore */
    
    .pricing-table,
    .pricing-table thead,
    .pricing-table tbody,
    .pricing-table th,
    .pricing-table td,
    .pricing-table tr {
        display: block;
    }
    .pricing-table {
        /* Hide table headers (but not display: none;, for accessibility) */
        
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        tr {
            margin-top: 25px;
        }
        tr td:nth-child(2),
        tr th:nth-child(2) {
            border-left: none;
        }
        td {
            /* Behave like a "row" */
            
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
            white-space: normal;
            text-align: left;
        }
        td:before {
            /* Now like a table header */
            
            position: absolute;
            /* Top/left values mimic padding */
            
            top: 50%;
            margin-top: -11px;
            left: 17px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            text-align: left;
            font-weight: bold;
        }
        /*
    Label the data
    */
        
        td:before {
            content: attr(data-title);
        }
    }
}

/* Pricing Layouts */
.pricing-layout-overflow-top{
    position: relative;
    margin-top: -232px;
}